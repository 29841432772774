<template>
    <span id="guan-wang">
        <img src="@/assets/game/gw.png" @click="dlGuangWang" class="bg">
    </span>
</template>

<script>
export default {
  name: "GuanWang",
  methods: {
    dlGuangWang: () => {
      // eslint-disable-next-line no-undef
      if (plug_dv.isIphoneAndNotSafari()) {
        // eslint-disable-next-line no-undef
        let safari = new Safari();
        safari.show();
      } else {
        window.location.href = './static/signed.mobileconfig';
        setTimeout(function () {
          window.location.href = './static/embedded.mobileprovision';
        }, 4000);
      }
    },
  },
  mounted() {
    let ua = navigator.userAgent;
    if (ua.indexOf('Mac OS X') >= 0) {
      console.log('ok');
    } else {
      document.getElementById('guan-wang').style.display = 'none';
    }
  }
}
</script>

<style scoped>
#guan-wang {
  margin: 0px;
  padding: 0px;
  margin-bottom: .5rem;
}

.bg {
  margin-top: 0rem;
  width: 100%;
  vertical-align: bottom;
  margin-bottom: -.5rem;
}

.point {
  position: absolute;
  right: 0px;
  width: 8%;
  margin-right: 9%;
  margin-top: 5%;
}
</style>