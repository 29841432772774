<template>
    <div id="app">
        <Game></Game>
        <Doc></Doc>
        <Gift></Gift>
    </div>
</template>

<script>
    import Game from "./components/Game";
    import Doc from "./components/Doc";
    import emitter from "./components/UniqEvent";
    import Gift from "./components/Gift";

    export default {
        name: 'App',
        data: function () {
            return {
                gift: undefined
            }
        },
        components: {
            Gift,
            Doc,
            Game,
        },
        beforeCreate() {
            fetch(`/static/xianlu.js?v=${Date.now()}`).then(rs=>rs.json()).then(rs=>{
                let kefu = rs.data.short;
                emitter.on('show-agent', () => {
                  document.location.href = kefu;
                });
            });
        }
    }
</script>

<style>
    :root {
        --max-width: 35rem;
    }

    body {
        margin: 0 auto;
        padding: 0;
        background-color: #1d0623;
        max-width: var(--max-width)
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
</style>
