<template>
  <div id="float-agent">
    <img src="@/assets/float-agent/13.png" v-on:click="showAgent()" style="z-index: 900;">
  </div>
</template>

<script>
import emitter from "./UniqEvent";

export default {
  name: "FloatAgent",
  methods: {
    showAgent: () => {
      emitter.emit('show-agent');
    },
  },
}
</script>

<style scoped>
#float-agent {
  position: fixed;
  right: 0;
  top: 50vh;
  z-index: 5000;
}

@media screen and (min-width: 768px) {
  #float-agent {
    position: fixed;
    right: calc(50vw - 18rem);
    top: 50vh;
    z-index: 5000;
  }
}

#float-agent img {
  width: 20vw;
  max-width: calc(var(--max-width) / 5);
}
</style>